import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    srds: {
      title: '지능형 무선 위험(산사태 등) 감지 시스템',
      detail1: '국토교통부 스마트시티 혁신제품 지정',
      detail2: 'KC 인증 / 특허 등록',
      marker: '혁신제품',
      moreinfo:
        'https://ppi.g2b.go.kr:8914/sm/dm/sch/searchGoodsDetail.do?invGdsIdntNo=00011743',
      text: '지능형 무선 위험감지 데이터 수집기는 사진 전송 가능한 LTE Cat.M1 방식으로 충격(진동)에 의한 기울기 변화 및 사진을 실시간문자로 알려줍니다. 오래된 건물이나 산사태 위험지역, 낙석방지 지역 등에 설치하여 24시간 모니터링 합니다.',
      spot: `코레일(KORAIL), 전북 무주군, SK그룹 연수원, 경북 고령군 등`,
      src: {
        img00: require('@/assets/images/solutions/srds/techguard.jpg'),
        img01: require('@/assets/images/solutions/srds/srds_construct2.jpg'),
        img02: require('@/assets/images/solutions/srds/srds_construct1.jpg'),
        img03: require('@/assets/images/solutions/srds/srds_construct3.jpg'),
        img04: require('@/assets/images/solutions/srds/srds_sk1.jpg'),
        img05: require('@/assets/images/solutions/srds/srds_goryeong2.jpg'),
        img06: require('@/assets/images/solutions/srds/srds_goryeong3.jpg'),
      },
      example: {
        img01: {
          location: '부천시',
          text: '균열감지센서',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_bucheon1.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_bucheon2.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_bucheon3.jpg'),
            img04: require('@/assets/images/solutions/srds/srds_bucheon4.jpg'),
            img05: require('@/assets/images/solutions/srds/srds_bucheon5.jpg'),
          },
        },
        img02: {
          location: '파주시',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_paju1.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_paju2.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_paju3.jpg'),
            img04: require('@/assets/images/solutions/srds/srds_paju4.jpg'),
            img05: require('@/assets/images/solutions/srds/srds_paju5.jpg'),
            img06: require('@/assets/images/solutions/srds/srds_paju6.jpg'),
          },
        },

        img03: {
          location: '코레일(KORAIL)',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_korail1.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_korail2.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_korail3.jpg'),
            img04: require('@/assets/images/solutions/srds/srds_korail4.jpg'),
            img05: require('@/assets/images/solutions/srds/srds_korail5.jpg'),
          },
        },
        img04: {
          location: '전북 무주군',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_construct2.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_construct1.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_construct3.jpg'),
          },
        },
        img05: {
          location: 'SK그룹 연수원',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_sk1.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_sk2.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_sk3.jpg'),
          },
        },
        img06: {
          location: '경북 고령군',
          src: {
            img01: require('@/assets/images/solutions/srds/srds_goryeong2.jpg'),
            img02: require('@/assets/images/solutions/srds/srds_goryeong3.jpg'),
            img03: require('@/assets/images/solutions/srds/srds_goryeong1.jpg'),
          },
        },
      },
      history: [
        '2024년 4차 산업혁명 Power Korea 대전 스마트시티상 2년 연속수상',
        '2023년 월드 스마트시티 엑스포(WSCE) 안전부문 우수기업 수상',
        '2023년 코레일(KORAIL) 납품',
        '2023년 월드 스마트시티엑스포 어워즈 ‘스마트시티 우수기업’ 수상',
        '2023년 "4차 산업혁명 Power Korea 대전" 스마트시티상 수상',
        '2023년 전북 무주군 설치',
        '2023년 SK그룹 연수원 설치',
        '2022년 조달청 혁신제품 시범구매사업 선정',
        '2022년 국토부 스마트시티 혁신제품 선정',
        '2021년 경북 고령군 시범 적용',
      ],
      youtube: 'https://www.youtube.com/embed/41o7nEgYA_w',
      url: '/srds'
    },

    swfm: {
      title: '친환경 무선 산불감지 및 확산 알림 시스템',
      detail: 'KC 인증 / 특허 등록',
      moreinfo: 0,
      text: '지능형 무선 산불 감지 시스템은 저전력이동통신인 LTE Cat.M1 기반으로 별도의 전기와 네트웍 연결 없이 실외에서 장기간 배터리만으로 동작 가능한 데이터 수집기를 사용합니다. 등산로 등 산불 발생 위험　지역에 설치하여 24시간 모니터링 합니다.',
      spot: `실증 진행 중(2024.07 ~ )`,
      src: {
        img00: require('@/assets/images/solutions/swfm/swfm_device.png'),
        img01: require('@/assets/images/solutions/swfm/swfm2.jpg'),
        img02: require('@/assets/images/solutions/swfm/swfm3.jpg'),
        img03: require('@/assets/images/solutions/swfm/swfm4.png'),
      },
      history: [
        '2024년 4차 산업혁명 Power Korea 대전 스마트시티상 2년 연속수상',
        '2024년 SK그룹 연수원 설치',
        '2024년 고객감동 경영대상 전문서비스부문 대상',
      ],
      example: {
        img01: {
          location: "SK그룹 연수원",
          src: {
            img01: require('@/assets/images/solutions/swfm/swfm_sk1.jpg'),
            img02: require('@/assets/images/solutions/swfm/swfm_sk2.jpg'),
            img03: require('@/assets/images/solutions/swfm/swfm_sk3.jpg')
          }
        }
      },
      youtube: '',
      url: '/swfm'
    },
    nematode: {
      title: '친환경 산림병해충 AI 모니터링 시스템',
      detail: 'KC 인증 / 특허 등록',
      moreinfo: 0,
      text: `산림병해충 모니터링 시스템은 산림 병해충 확산 문제를 최소화 하기 위해 개발중인 친환경 솔루션입니다. AI 분석을 통해 병해충 감염 및 고사목을 조기에 발견하고 이를 관리자에게 사진, 위치 정보 등을 포함한 데이터를 실시간 문자로 알려줍니다.  `,
      spot: `실증 진행 중(2024.07 ~ )`,
      src: {
        img00: require('@/assets/images/solutions/nematode/nematode_device.png'),
      },
      history: [
        '2024년 4차 산업혁명 Power Korea 대전 스마트시티상 2년 연속수상',
        '2024년 SK그룹 연수원 설치',
      ],
      example: {
        img01: {
          location: "SK그룹 연수원",
          src: {
            img01: require('@/assets/images/solutions/nematode/pwdm_sk1.jpg'),
            img02: require('@/assets/images/solutions/nematode/pwdm_sk2.jpg'),
            img03: require('@/assets/images/solutions/nematode/pwdm_sk3.jpg')
          }
        }
      },
      url: '/nematode'
    },
    news: {
      articles: [
        {
          index: 51,
          img: require('@/assets/images/news/article51.jpg'),
          header:
            '테크나인, 2024 AIOT 국제전시회 참가…360도 예찰 가능한 ‘지능형 산림재난 IoT 모니터링 시스템’ 선보여',
          date: '2024.11.01',
          desc: '친환경 아웃도어 IoT 솔루션 그룹 테크나인㈜(Tech9, 대표 이호동)이 10월 30일부터 11월 1일까지 3일간, 코엑스에서 열리는 ‘2024 AIoT 국제전시회’에 참가해 동서남북 4면에 카메라를 탑재한 지능형 산림재난 IoT 모니터링 시스템을 선보였다.',
          src: 'https://www.newsro.kr/article243/560481/',
        },
        {
          index: 50,
          img: require('@/assets/images/news/article50.jpg'),
          header:
            '테크나인 ‘월드 스마트 시티 엑스포 2024’ 참가… 3대 산림재난 안전 솔루션 제시',
          date: '2024.09.04',
          desc: '친환경 IoT솔루션 그룹 테크나인(주)(TECH9, 대표 이호동)은 9월 3일부터 5일까지 3일간 경기 고양시 킨텍스 제1전시장에서 개최되는 ‘월드 스마트 시티 엑스포 2024(World Smart City Expo Korea 2024, 이하 WSCE)에 참가해 ‘3대 산림재난 안전 솔루션’을 제시했다.',
          src: 'https://www.newsro.kr/article243/508394/',
        },
        {
          index: 49,
          img: require('@/assets/images/news/article49.png'),
          header:
            '[2024 Power Korea 대전]스마트시티상/2년 연속-테크나인',
          date: '2024.08.28',
          desc: '친환경 AIoT솔루션 전문기업 테크나인(대표 이호동·사진)은 "2024 4차 산업혁명 Power Korea 대전"에서 스마트시티 부문을 2년 연속 수상했다.',
          src: 'https://www.etnews.com/20240820000350',
        },
        {
          index: 48,
          img: require('@/assets/images/news/article48.jpg'),
          header:
            '테크나인, ‘2024년 4차 산업혁명 POWER KOREA 대전’ 스마트시티상 2년연속 수상!',
          date: '2024.08.23',
          desc: '테크나인㈜(TECH9, 대표 이호동)은 8월 21일(수) 국회도서관 대강당에서 개최된 제9회 ‘2024년 4차 산업혁명 POWER KOREA 대전’에서 ‘스마트시티상’을 2년 연속 수상했다고 밝혔다',
          src: 'https://kr.aving.net/news/articleView.html?idxno=1793114',
        },
        {
          index: 47,
          img: require('@/assets/images/news/article47.jpg'),
          header:
            '테크나인, ‘2024년 4차 산업혁명 POWER KOREA 대전’ 스마트시티상 2년연속 수상!',
          date: '2024.08.23',
          desc: '친환경 IoT솔루션 그룹 테크나인(주)(TECH9, 대표 이호동)은 8월 21일(수) 국회도서관 대강당에서 개최된 제9회 ‘2024년 4차 산업혁명 POWER KOREA 대전’에서 ‘스마트시티상’을 2년연속 수상했다고 밝혔다',
          src: 'https://www.newsro.kr/article243/492152/',
        },
        {
          index: 46,
          img: require('@/assets/images/news/article46.png'),
          header:
            '[월간인물 6월호] 설치와 관리 용이한 저전력 이동통신 기반 AIoT 솔루션으로 국민의 안전 지킨다',
          date: '2024.06.04',
          desc: '테크나인㈜은 설치 및 이동이 용이한 LPWAN 기반 친환경 AIoT 제품으로 실시간 예찰 지역을 넓히며 재난 및 안전사고를 예방하고, 피해를 최소화하는 데 앞장서 왔다',
          src: 'https://www.monthlypeople.com/news/articleView.html?idxno=700481',
        },
        {
          index: 45,
          img: require('@/assets/images/news/article45.jpg'),
          header:
            '테크나인, 2024년 우수상용품 시범사용 제품설명회 참가…‘지능형 무선 위험(산사태 등) 감지 시스템’ 소개',
          date: '2024.05.29',
          desc: '테크나인㈜(TECH9, 대표 이호동)은 5월 29일(수)과 30일(목) 양일간 대전컨벤션센터 제2전시장에서 개최하는 국방부 ‘2024년 우수상용품 시범사용 제품설명회’에 참가해 ‘지능형 무선 위험(산사태 등) 감지 시스템을 선보인다',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2024%eb%85%84-%ec%9a%b0%ec%88%98%ec%83%81%ec%9a%a9%ed%92%88-%ec%8b%9c%eb%b2%94%ec%82%ac%ec%9a%a9-%ec%a0%9c%ed%92%88%ec%84%a4%eb%aa%85%ed%9a%8c-%ec%b0%b8%ea%b0%80-%ec%a7%80%eb%8a%a5%ed%98%95-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98%ec%82%b0%ec%82%ac%ed%83%9c-%eb%93%b1-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%86%8c%ea%b0%9c/',
        },
        {
          index: 44,
          img: require('@/assets/images/news/article44.jpg'),
          header:
            '테크나인, 친환경 지능형 IoT 3대 산림재난 모니터링 시스템 ‘ESG 친환경 제품 브랜드‘ 수상',
          date: '2024.05.13',
          desc: '아웃도어 IoT 솔루션 그룹 테크나인(주)(TECH9, 대표 이호동)은 지난 5월 10일 롯데호텔에서 열린 ‘제18회 국가지속가능 ESG 컨퍼런스’ 시상식에서 국가지속가능 ESG 우수기업으로 선정돼 ‘ESG 친환경 제품 브랜드’상을 수상했다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%b9%9c%ed%99%98%ea%b2%bd-%ec%a7%80%eb%8a%a5%ed%98%95-iot-3%eb%8c%80-%ec%82%b0%eb%a6%bc%ec%9e%ac%eb%82%9c-%eb%aa%a8%eb%8b%88%ed%84%b0%eb%a7%81-%ec%8b%9c%ec%8a%a4%ed%85%9c-esg-%ec%b9%9c%ed%99%98%ea%b2%bd-%ec%a0%9c%ed%92%88-%eb%b8%8c%eb%9e%9c%eb%93%9c-%ec%88%98%ec%83%81/',
        },
        {
          index: 43,
          img: require('@/assets/images/news/article43.jpg'),
          header:
            '테크나인, ‘코리아 나라장터 엑스포 2024(KOPPEX 2024)’ 참가…3대 산림재난 IoT솔루션 소개',
          date: '2024.04.17',
          desc: '친환경 지능형 IoT 솔루션 그룹 테크나인㈜(Tech9, 대표 이호동)이 4월 17일(수)부터 19일(금)까지 3일간, 킨텍스에서 열리는 ‘코리아 나라장터 엑스포 2024(KOPPEX 2024)’에 참가해 최신 저전력이동통신 기반 3대 산림재난 IoT솔루션을 선보였다.',
          src: 'https://www.newsro.kr/%ED%85%8C%ED%81%AC%EB%82%98%EC%9D%B8-%EC%BD%94%EB%A6%AC%EC%95%84-%EB%82%98%EB%9D%BC%EC%9E%A5%ED%84%B0-%EC%97%91%EC%8A%A4%ED%8F%AC-2024koppex-2024-%EC%B0%B8%EA%B0%803%EB%8C%80-%EC%82%B0%EB%A6%BC%EC%9E%AC%EB%82%9C-iot%EC%86%94%EB%A3%A8%EC%85%98-%EC%86%8C%EA%B0%9C/',
        },
        {
          index: 42,
          img: require('@/assets/images/news/article42.jpg'),
          header:
            '테크나인, 지능형 산불감시 시스템 ‘2024 고객감동경영대상’ 전문서비스 부문 대상 수상',
          date: '2024.01.11',
          desc: '테크나인(주)(TECH9, 대표 이호동) ‘지능형 산불감시 시스템’이 10일, 서울 소공동 롯데호텔에서 열린 ‘2024 고객감동경영대상’에서 전문 서비스 부문 대상을 수상했다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%ec%82%b0%eb%b6%88%ea%b0%90%ec%8b%9c-%ec%8b%9c%ec%8a%a4%ed%85%9c-2024-%ea%b3%a0%ea%b0%9d%ea%b0%90%eb%8f%99%ea%b2%bd%ec%98%81%eb%8c%80%ec%83%81-%ec%a0%84%eb%ac%b8%ec%84%9c%eb%b9%84%ec%8a%a4-%eb%b6%80%eb%ac%b8-%eb%8c%80%ec%83%81-%ec%88%98%ec%83%81/',
        },
        {
          index: 41,
          img: require('@/assets/images/news/article41.jpg'),
          header:
            '2024 고객감동경영대상, 테크나인, 지능형 IoT로 산불·산사태·병해충 모니터링',
          date: '2024.01.10',
          desc: '지능형 산불 감시 시스템으로 전문서비스 부문 대상을 수상한 테크나인(대표 이호동·사진)은 최신 저전력이동통신(LTE Cat.M1 등) 기반으로 3대 산림 재난(산불·산사태·산림병해충)을 모니터링하는 친환경 AIoT(인공지능+사물인터넷) 솔루션 전문 기업이다.',
          src: 'https://www.hankyung.com/article/2024010909191',
        },
        {
          index: 40,
          img: require('@/assets/images/news/article40.jpg'),
          header:
            '테크나인, ‘2023 AIoT 국제전시회’ 참가…3대 산림재난 IoT솔루션 소개',
          date: '2023.10.12',
          desc: '아웃도어 IoT 솔루션 그룹 테크나인㈜(Tech9, 대표 이호동)이 10월 11일부터 13일까지 3일간, 코엑스에서 열리는 ‘2023 AIoT 국제전시회’에 참가해 3대 산림재난 IoT솔루션을 소개했다.',
          src: 'https://www.newsro.kr/%ED%85%8C%ED%81%AC%EB%82%98%EC%9D%B8-2023-aiot-%EA%B5%AD%EC%A0%9C%EC%A0%84%EC%8B%9C%ED%9A%8C-%EC%B0%B8%EA%B0%803%EB%8C%80-%EC%82%B0%EB%A6%BC%EC%9E%AC%EB%82%9C-iot%EC%86%94%EB%A3%A8%EC%85%98-%EC%86%8C%EA%B0%9C/',
        },
        {
          index: 39,
          img: require('@/assets/images/news/article39.jpg'),
          header:
            '테크나인, 월드 스마트시티엑스포 어워즈 ‘스마트시티 우수기업’ 수상',
          date: '2023.09.07',
          desc: '아웃도어 IoT 솔루션 그룹 테크나인㈜(Tech9, 대표 이호동)은 9월 6일(수)부터 8일(금)까지 일산 킨텍스 제1전시장에서 열리는 ‘2023 월드 스마트시티 엑스포’(이하 WSCE)가‘에 참가해 ‘시설물 안전과 스마트 공원 자동관수 시스템’을 선보였다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2023-%ec%9b%94%eb%93%9c-%ec%8a%a4%eb%a7%88%ed%8a%b8%ec%8b%9c%ed%8b%b0-%ec%97%91%ec%8a%a4%ed%8f%ac-%ec%b0%b8%ea%b0%80-%ec%8b%9c%ec%84%a4%eb%ac%bc-%ec%95%88%ec%a0%84-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c%ea%b3%bc-%ec%8a%a4%eb%a7%88%ed%8a%b8-%ea%b3%b5%ec%9b%90-%ec%9e%90%eb%8f%99%ea%b4%80%ec%88%98-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%84%a0%eb%b3%b4%ec%97%ac/',
        },
        {
          index: 38,
          img: require('@/assets/images/news/article38.png'),
          header:
            '"2023 AIoT 국제전시회 및 컨퍼런스" 테크나인 참가 예정',
          date: '2023.08.18',
          desc: 'IoT 솔루션 그룹 테크나인(주)(Tech9, 대표 이호동)은 10월 11일부터 13일까지 COEX에서 개최되는 "2023 AIoT 국제전시회 및 컨퍼런스"에 참가할 예정이다.',
          src: 'http://iotkorea.or.kr/2023/kor/index.asp',
        },
        {
          index: 37,
          img: require('@/assets/images/news/article37.png'),
          header:
            '테크나인, "2023 대한민국 안전산업박람회"에서 재난안전 솔루션 소개 예정',
          date: '2023.08.11',
          desc: 'IoT 솔루션 그룹 테크나인(주)(Tech9, 대표 이호동)은 9월 13일부터 15일까지 경기도 고양 KINTEX에서 개최되는 "2023 대한민국 안전산업박람회"에 참가할 예정이다.',
          src: 'https://www.youtube.com/watch?v=eS6gTbBf85w&t=5s',
        },
        {
          index: 36,
          img: require('@/assets/images/news/article36.png'),
          header:
            '테크나인, "2023 월드 스마트시티 엑스포" 참가…IoT활용 3대 재난 솔루션 소개 예정',
          date: '2023.08.11',
          desc: 'IoT 솔루션 그룹 테크나인(주)(Tech9, 대표 이호동)은 9월 6일부터 8일까지 경기도 고양 킨텍스에서 개최되는 "2023 월드 스마트시티 엑스포"에 참가할 예정이다.',
          src: 'https://www.worldsmartcityexpo.com/fairDash.do',
        },
        {
          index: 35,
          img: require('@/assets/images/news/article35.png'),
          header:
            '[4차 산업혁명 POWER KOREA 대전] 현실로 다가온 4차산업혁명 시대 기술 방향 제시',
          date: '2023.07.19',
          desc: '눈앞에서 현실이 된 4차산업혁명의 발전현황과 미래 방향을 제시하는 "2023 4차 산업혁명 파워코리아(Power Korea) 대전"이 지난 12일 국회의원회관 제2소회의실에서 120여 명이 참석한 가운데 성황리 개최됐다',
          src: 'https://www.etnews.com/20230718000088',
        },
        {
          index: 34,
          img: require('@/assets/images/news/article34.jpg'),
          header: '[4차산업혁명 POWER KOREA 대전] 테크나인',
          date: '2023.07.19',
          desc: '테크나인(대표 이호동)은 4차 산업혁명 스마트시티 부문을 수상했다. 테크나인은 친환경 AIoT솔루션을 통해 스마트시티 시대 마중물 역할을 수행하고 있다.',
          src: 'https://www.etnews.com/20230718000139',
        },
        {
          index: 33,
          img: require('@/assets/images/news/article33.png'),
          header:
            '‘2023년 4차 산업혁명 Power Korea 대전’ 스마트시티상 수상... "ICT 융복합 기술 통해 스마트한 산업경제 형성에 기여"',
          date: '2023.07.13',
          desc: '테크나인은 ICT 융복합 기술을 활용해 4차 산업혁명을 선도적으로 추진하고, 신성장 동력을 마련하여 스마트한 산업경제 형성에 크게 기여한 바 우수기업에 선정됐다',
          src: 'https://kr.aving.net/news/articleView.html?idxno=1781468',
        },
        {
          index: 32,
          img: require('@/assets/images/news/article32.jpg'),
          header:
            '테크나인, ‘2023년 4차 산업혁명 Power Korea 대전’ 스마트시티상 수상',
          date: '2023.07.13',
          desc: '아웃도어 IoT솔루션 그룹 테크나인(주)(TECH9, 대표 이호동)은 12일 국회의원회관 제2소회의실에서 개최된 제8회 ‘2023년 4차 산업혁명 Power Korea 대전’에서 ‘4차산업혁명 스마트시티상’을 수상했다고 밝혔다.',
          src: 'https://www.newsro.kr/%ED%85%8C%ED%81%AC%EB%82%98%EC%9D%B8-2023%EB%85%84-4%EC%B0%A8-%EC%82%B0%EC%97%85%ED%98%81%EB%AA%85-power-korea-%EB%8C%80%EC%A0%84-%EC%8A%A4%EB%A7%88%ED%8A%B8%EC%8B%9C%ED%8B%B0%EC%83%81-%EC%88%98%EC%83%81/',
        },
        {
          index: 31,
          img: require('@/assets/images/news/article31.jpg'),
          header:
            '2023년 1차 조달청 혁신제품 시범구매 대표제품 ‘테크나인 지능형 무선 위험감지 시스템’',
          date: '2023.04.24',
          desc: '조달청은 지난 11일 2023년 1분기 157억 원 규모의 91개 혁신제품을 시범 사용할 147개 기관을 선정하고 그 결과를 혁신장터에 공개했다.',
          src: 'https://www.newsro.kr/2023%eb%85%84-1%ec%b0%a8-%ec%a1%b0%eb%8b%ac%ec%b2%ad-%ed%98%81%ec%8b%a0%ec%a0%9c%ed%92%88-%ec%8b%9c%eb%b2%94%ea%b5%ac%eb%a7%a4-%eb%8c%80%ed%91%9c%ec%a0%9c%ed%92%88-%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c/',
        },
        {
          index: 30,
          img: require('@/assets/images/news/article30.jpg'),
          header:
            '테크나인, ‘저전력 이동통신 기반 무선 산불감지 및 확산 알림 장치’ 특허 취득',
          date: '2023.03.23',
          desc: '최근의 산불은 기후변화로 인해 복합화·대형화 되고 있으며, 지금도 곳곳에 산불이 끊이지 않고 있어, 실시간 산불감시 및 골든타임(golden time) 내 선제적 대응 가능한 모니터링 수단이 필요한 시점이다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a0%80%ec%a0%84%eb%a0%a5-%ec%9d%b4%eb%8f%99%ed%86%b5%ec%8b%a0-%ea%b8%b0%eb%b0%98-%eb%ac%b4%ec%84%a0-%ec%82%b0%eb%b6%88%ea%b0%90%ec%a7%80-%eb%b0%8f-%ed%99%95%ec%82%b0-%ec%95%8c%eb%a6%bc-%ec%9e%a5%ec%b9%98-%ed%8a%b9%ed%97%88-%ec%b7%a8%eb%93%9d/',
        },
        {
          index: 29,
          img: require('@/assets/images/news/article29.jpg'),
          header:
            '테크나인, 지능형 위험(산사태 등) 감지 시스템…조달청 ‘국민재난 안전분야 우수장비 혁신제품’ 선정!',
          date: '2023.03.09',
          desc: '테크나인㈜의 ‘지능형 위험(산사태 등) 감지 시스템’이 조달청 주관 ‘국민재난 안전분야 우수장비 혁신제품’에 선정됐다고 9일 밝혔다. ‘국민재난 안전분야 우수장비 혁신제품’은 정부 9개 주무부처에서 총 47개 제품을 선정했으며, 국토부 선정제품은 테크나인 지능형 위험(산사태 등) 감지 시스템이 유일하다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%ec%9c%84%ed%97%98%ec%82%b0%ec%82%ac%ed%83%9c-%eb%93%b1-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%a1%b0%eb%8b%ac%ec%b2%ad-%ea%b5%ad%eb%af%bc%ec%9e%ac%eb%82%9c-%ec%95%88%ec%a0%84%eb%b6%84%ec%95%bc-%ec%9a%b0%ec%88%98%ec%9e%a5%eb%b9%84-%ed%98%81%ec%8b%a0%ec%a0%9c%ed%92%88-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 28,
          img: require('@/assets/images/news/article28.jpg'),
          header:
            '테크나인 ‘지능형 위험(산사태 등) 감지 시스템’ 서울디지털재단 실증지원사업 성공…사업확장 기대!',
          date: '2023.02.28',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(TECH9, 대표 이호동)의 ‘지능형 위험(산사태 등) 감지 시스템’이 서울디지털재단(SDF)의 실증 지원사업 성공 판정을 받았다고 28일 밝혔다.',
          src: 'https://www.newsro.kr/%ED%85%8C%ED%81%AC%EB%82%98%EC%9D%B8-%EC%A7%80%EB%8A%A5%ED%98%95-%EC%9C%84%ED%97%98%EC%82%B0%EC%82%AC%ED%83%9C-%EB%93%B1-%EA%B0%90%EC%A7%80-%EC%8B%9C%EC%8A%A4%ED%85%9C-%EC%84%9C%EC%9A%B8%EB%94%94%EC%A7%80%ED%84%B8%EC%9E%AC%EB%8B%A8-%EC%8B%A4%EC%A6%9D%EC%A7%80%EC%9B%90%EC%82%AC%EC%97%85-%EC%84%B1%EA%B3%B5-%EC%82%AC%EC%97%85%ED%99%95%EC%9E%A5-%EA%B8%B0%EB%8C%80/',
        },
        {
          index: 27,
          img: require('@/assets/images/news/article27.png'),
          header:
            '테크나인 지능형 무선 위험 감지 시스템 ‘테크가드’…중기부 기술개발제품 시범구매제도 사업 선정',
          date: '2023.01.27',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(TECH9, 대표 이호동)의 ‘지능형 무선 위험 감지 시스템(모델명: SCM-SRDS)’이 중기부 ‘기술개발제품 시범구매제도’ 사업에 선정됐다고 27일 밝혔다.',
          src: 'https://www.newsro.kr/테크나인-지능형-무선-위험-감지-시스템-테크가드-중기부-기술개발제품-시범구매제도-사업-선정/',
        },
        {
          index: 26,
          img: require('@/assets/images/news/article26.jpg'),
          header:
            '테크나인, ‘지능형 무선 위험(산사태) 감지 시스템’ 조달청 혁신제품 시범구매사업 선정!',
          date: '2022.10.24',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(tech9, 대표 이호동)의 ‘지능형 무선 위험(산사태 등)감지 시스템(모델명: SCM-SRDS)’이 조달청 ‘22년 혁신제품 시범구매사업(공고 5차)’사업에 선정됐다고 24일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98-%ec%82%b0%ec%82%ac%ed%83%9c-%eb%93%b1-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%a1%b0%eb%8b%ac%ec%b2%ad-%ed%98%81%ec%8b%a0%ec%a0%9c%ed%92%88-%ec%8b%9c%eb%b2%94%ea%b5%ac%eb%a7%a4%ec%82%ac%ec%97%85-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 25,
          img: require('@/assets/images/news/article25.png'),
          header:
            '테크나인, ‘2022 AIoT 국제전시회’ 참가…재난안전 IoT 솔루션 시장 공략!',
          date: '2022.10.20',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(tech9, 대표 이호동)은 19일부터 21일까지 3일간 서울 코엑스에서 2022 ‘AIoT(지능형사물인터넷) 국제전시회’에 참가해 ‘지능형 무선 위험(산사태 등)감지 시스템’등 재난안전 IoT 솔루션을 선보이며 국내 시장 공략에 나섰다.',
          src: 'https://youtu.be/QKt4MazsW8c',
        },
        {
          index: 24,
          img: require('@/assets/images/news/article24.jpg'),
          header:
            '테크나인, ‘2022 AIoT 국제전시회’ 참가…재난안전 IOT 솔루션 선보여',
          date: '2022.10.19',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(tech9, 대표 이호동)은 19일부터 21일까지 3일간 서울 코엑스에서 2022 ‘AIoT(지능형사물인터넷) 국제전시회’에 참가해 ‘지능형 무선 위험(산사태 등)감지 시스템’등 재난안전 IOT 솔루션을 선보였다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2022-aiot-%ea%b5%ad%ec%a0%9c%ec%a0%84%ec%8b%9c%ed%9a%8c-%ec%b0%b8%ea%b0%80%ec%9e%ac%eb%82%9c%ec%95%88%ec%a0%84-iot-%ec%86%94%eb%a3%a8%ec%85%98-%ec%84%a0%eb%b3%b4%ec%97%ac/',
        },
        {
          index: 23,
          img: require('@/assets/images/news/article23.jpg'),
          header:
            '테크나인, ‘제8회 대한민국 안전산업박람회’ 참가…재난안전 IOT 솔루션 선보여',
          date: '2022.10.12',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(tech9, 대표 이호동)은 10월 12일부터 14일까지 3일간 대구 엑스코(동관)에서 열리는 ‘제8회 대한민국 안전산업박람회(K-Safety EXPO 2022)에 참가해 ‘지능형 무선 위험(산사태 등)감지 시스템’등 재난안전 IOT 솔루션을 선보인다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a0%9c8%ed%9a%8c-%eb%8c%80%ed%95%9c%eb%af%bc%ea%b5%ad-%ec%95%88%ec%a0%84%ec%82%b0%ec%97%85%eb%b0%95%eb%9e%8c%ed%9a%8c-%ec%b0%b8%ea%b0%80%ec%9e%ac%eb%82%9c%ec%95%88%ec%a0%84-iot-%ec%86%94%eb%a3%a8%ec%85%98-%ec%84%a0%eb%b3%b4%ec%97%ac/',
        },
        {
          index: 22,
          img: require('@/assets/images/news/article22.jpg'),
          header:
            '테크나인, 지능형 무선 위험(산사태 등) 감지 시스템 ‘중기부 기술개발제품 시범구매’ 선정!',
          date: '2022.10.11',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(tech9,대표 이호동)의 ‘지능형 무선 위험(산사태 등)감지 시스템(모델명: SCM-SRDS)’이 중소벤처기업부 ‘22년 기술개발제품 시범구매(공고 3차)’사업에 선정됐다고 11일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98%ec%82%b0%ec%82%ac%ed%83%9c-%eb%93%b1-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%a4%91%ea%b8%b0%eb%b6%80-%ea%b8%b0%ec%88%a0%ea%b0%9c%eb%b0%9c%ec%a0%9c%ed%92%88-%ec%8b%9c%eb%b2%94%ea%b5%ac%eb%a7%a4-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 21,
          img: require('@/assets/images/news/article21.jpg'),
          header:
            '테크나인, 서울디지털재단 주관 ‘스마트도시 솔루션 기술검증(PoC)과 실증 지원사업’ 선정!',
          date: '2022.09.28',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동, tech9.co.kr)의 ‘지능형 산사태 감지 시스템(모델명: SCM-SRDS)’이 서울디지털재단에서 주관하는 ‘스마트도시 솔루션 기술검증(PoC)과 실증 지원사업’에 선정됐다고 28일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%84%9c%ec%9a%b8%eb%94%94%ec%a7%80%ed%84%b8%ec%9e%ac%eb%8b%a8-%ec%a3%bc%ea%b4%80-%ec%8a%a4%eb%a7%88%ed%8a%b8%eb%8f%84%ec%8b%9c-%ec%86%94%eb%a3%a8%ec%85%98-%ea%b8%b0%ec%88%a0%ea%b2%80%ec%a6%9dpoc%ea%b3%bc-%ec%8b%a4%ec%a6%9d-%ec%a7%80%ec%9b%90%ec%82%ac%ec%97%85-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 20,
          img: require('@/assets/images/news/article20.jpg'),
          header:
            '테크나인 ‘지능형 산사태 감지 시스템’…조달청 ‘혁신장터 기획전’ 참가!',
          date: '2022.09.13',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동, tech9.co.kr)의 ‘지능형 산사태 감지 시스템(모델명: SCM-SRDS)’이 조달청 폭우·폭염 관련 혁신제품에 선정되어 ‘혁신장터(혁신제품 전용몰) 기획전’에 참가했다고 13일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%ec%82%b0%ec%82%ac%ed%83%9c-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%a1%b0%eb%8b%ac%ec%b2%ad-%ed%98%81%ec%8b%a0%ec%9e%a5%ed%84%b0-%ea%b8%b0%ed%9a%8d%ec%a0%84-%ec%b0%b8%ea%b0%80/',
        },
        {
          index: 19,
          img: require('@/assets/images/news/article19.png'),
          header:
            '‘TECH GUARD’, 조달청 폭우·폭염 관련 혁신제품 ‘혁신장터 기획전’ 제품 선정',
          date: '2022.08.31',
          desc: '테크나인은 지난 23일, 조달청에서 주관하는 폭우·폭염 관련 혁신제품 ‘혁신장터 기획전’ 제품에 ‘TECH GUARD’가 선정되었음을 밝혔다. 이번 기획전은 최근 기후변화로 잦아지고 있는 폭우·폭염으로 인한 인명·재산 피해의 예방과 회복에 필요한 혁신제품을 한 곳에 모아 각 공공기관에서 손쉽게 찾고 구매하도록 지원하기 위해 마련됐다.',
          src: 'https://www.korea.kr/news/pressReleaseView.do?newsId=156522012',
        },
        {
          index: 18,
          img: require('@/assets/images/news/article18.png'),
          header: '[영상]테크나인, 지능형 위험(산사태 등) 감지 시스템 선보여',
          date: '2022.07.25',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동)은 전기나 무선 인터넷이 지원되지 않는 야외에서 사용 가능한 실외 무선 위험(산사태 등) 감지 시스템을 최근 선보였다.',
          src: 'https://youtu.be/ET8tp36zYSg',
        },
        {
          index: 17,
          img: require('@/assets/images/news/article17.jpg'),
          header:
            '테크나인, 지능형 무선 위험 감지시스템 ‘TECH GUARD’…조달청 혁신장터 등록',
          date: '2022.06.16',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동, tech9.co.kr)은 전기나 무선 인터넷이 지원되지 않는 야외에서 사용 가능한 실외 지능형 무선 위험(산사태 등) 감지 시스템인 ‘TECH GUARD(이하 테크 가드)’가 조달청 혁신장터에 최근 등록됐다고 16일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%eb%ac%b4%ec%84%a0-%ec%9c%84%ed%97%98-%ea%b0%90%ec%a7%80%ec%8b%9c%ec%8a%a4%ed%85%9c-tech-guard%ec%a1%b0%eb%8b%ac%ec%b2%ad-%ed%98%81%ec%8b%a0%ec%9e%a5%ed%84%b0-%eb%93%b1%eb%a1%9d/',
        },
        {
          index: 16,
          img: require('@/assets/images/news/article16.png'),
          header:
            '테크나인, ‘저전력 이동통신 기반 친환경 무선 산불 감지 및 확산 알림 시스템’, 중기부 신제품개발 사업 선정',
          date: '2022.04.17',
          desc: '‘저전력 이동통신 기반 친환경 무선 산불 감지 및 확산 알림 시스템’은 사진 및 센서 값 분석 및 딥러닝 방식으로 산불 발생 징후를 조기에 탐지하여 피해 확산을 방지하는 기술로 해당 사업이 테크나인㈜(대표 이호동)은 17일 중기부 신제품 개발 사업으로 선정 되었다고 밝혔다.',
          src: '#',
        },
        {
          index: 15,
          img: require('@/assets/images/news/article15.png'),
          header:
            '테크나인, 지능형 위험감지 센서 ‘TECH GUARD’, 국토부 혁신제품 지정',
          date: '2022.03.23',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동)은 14일 전기나 무선 인터넷이 지원되지 않는 야외에서 사용 가능한 실외 무선 위험(산사태 등) 감지 시스템인 ‘TECH GUARD(테크 가드)’가 국토부 혁신제품으로 지정 되었다고 밝혔다.',
          src: '#',
        },
        {
          index: 14,
          img: require('@/assets/images/news/article14.jpeg'),
          header:
            '한국정책역량개발원, 지능형 IOT 충격-붕괴 위험 감지 기술혁신시스템 개발, 보급',
          date: '2022.02.25',
          desc: '디지털혁신을 통한 삶의 질 향상으로 지역소멸 위기극복대응 가속 KGI한국정책역량개발원(원장 신판용)은 ‘지역균형발전과 공무원 역량강화’의 미션을 가지고, 정부정책분석, 공모사업개발, 중장기발전계획수립 등 다양한 분야에서 연구·조사·평가 및 교육프로젝트를 수행하고 있는 가운데, 지역소멸의 위기극복을 위해',
          src: 'https://www.newsro.kr/%ed%95%9c%ea%b5%ad%ec%a0%95%ec%b1%85%ec%97%ad%eb%9f%89%ea%b0%9c%eb%b0%9c%ec%9b%90-%ec%a7%80%eb%8a%a5%ed%98%95-iot-%ec%b6%a9%ea%b2%a9-%eb%b6%95%ea%b4%b4-%ec%9c%84%ed%97%98-%ea%b0%90%ec%a7%80-%ea%b8%b0%ec%88%a0%ed%98%81%ec%8b%a0%ec%8b%9c%ec%8a%a4%ed%85%9c-%ea%b0%9c%eb%b0%9c-%eb%b3%b4%ea%b8%89/',
        },
        {
          index: 13,
          img: require('@/assets/images/news/article13.png'),
          header:
            "테크나인, '22년도 AIoT(AI+IoT) 서비스ㆍ제품 및 기업편람 등재",
          date: '2022.02.16',
          desc: "테크나인(주)(대표 이호동)은 작년 11월 출시한 지능형(산사태 등) 감지 시스템 'TECH GUARD'와 함께 주력 제품들이 한국지능형사물인터넷협회에서 발행하는 '2022년도 AIoT(AI+IoT) 서비스ㆍ제품 및 기업편람'에 등재되었음을 밝혔다.",
          src: '#',
        },
        {
          index: 12,
          img: require('@/assets/images/news/article12.png'),
          header: '테크나인, 2022년 AI 바우처 지원사업 공급기업 Pool 등록',
          date: '2021.12.06',
          desc: "아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동)은 저전력이동통신 기반 IoT 데이터 수집기를 통해 수집된 사진을 분석하여 과수화상병 등 과수/노지 작물의 병해 발생여부를 판단/보고하는 AI 솔루션을 자체 보유하고 있으며 '22년 AI 바우처 지원사업 공급기업Pool 등록 되었다고 밝혔다.",
          src: '#',
        },
        {
          index: 11,
          img: require('@/assets/images/news/article11.jpg'),
          header:
            '테크나인, 지능형 위험(산사태 등) 감지 시스템 ‘TECH GUARD’ 출시',
          date: '2021.11.17',
          desc: '아웃도어 IoT솔루션 그룹 테크나인㈜(대표 이호동)은 전기나 무선 인터넷이 지원되지 않는 야외에서 사용 가능한 실외 무선 위험(산사태 등) 감지 시스템 ‘TECH GUARD(테크 가드)’를 출시한다고 17일 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%a7%80%eb%8a%a5%ed%98%95-%ec%9c%84%ed%97%98%ec%82%b0%ec%82%ac%ed%83%9c-%eb%93%b1-%ea%b0%90%ec%a7%80-%ec%8b%9c%ec%8a%a4%ed%85%9c-tech-guard-%ec%b6%9c%ec%8b%9c/',
        },
        {
          index: 10,
          img: require('@/assets/images/news/article10.png'),
          header:
            '테크나인, 행안부 주관 국민생활안전포럼에서 ‘다목적 실외 환경/안전 모니터링 솔루션’ 발표',
          date: '2021.09.16',
          desc: '아웃도어 IoT 선도기업 테크나인(Tech9, 대표 이호동)은 지난 16일, 행안부가 주관하고 한국지능형사물인터넷협회가 운영하는 국민생활안전포럼에서 ‘다목적 실외 환경/안전 모니터링 기술’에 관해 발표했다고 23일(목) 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ea%b5%ad%eb%af%bc%ec%83%9d%ed%99%9c%ec%95%88%ec%a0%84%ed%8f%ac%eb%9f%bc%ec%97%90%ec%84%9c-%eb%8b%a4%eb%aa%a9%ec%a0%81-%ec%8b%a4%ec%99%b8-%ed%99%98%ea%b2%bd-%ec%95%88%ec%a0%84-%eb%aa%a8%eb%8b%88%ed%84%b0%eb%a7%81-%ea%b8%b0%ec%88%a0-%eb%b0%9c%ed%91%9c/',
        },
        {
          index: 9,
          img: require('@/assets/images/news/article09.jpeg'),
          header:
            '테크나인, 서울TP 주관 2021년 지역기업 <br/ > 혁신성장 바우처지원사업 공급기업 선정!',
          date: '2021.07.21',
          desc: '아웃도어 IoT 선도기업 테크나인(Tech9, 대표 이호동)은 서울테크노파크가 주관한 ‘2021년 서울 지역기업 혁신성장 바우처지원사업 공급기업’에 선정됐다고 21일(수) 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%84%9c%ec%9a%b8tp-%ec%a3%bc%ea%b4%80-2021%eb%85%84-%ec%84%9c%ec%9a%b8-%ec%a7%80%ec%97%ad%ea%b8%b0%ec%97%85-%ed%98%81%ec%8b%a0%ec%84%b1%ec%9e%a5-%eb%b0%94%ec%9a%b0%ec%b2%98%ec%a7%80%ec%9b%90%ec%82%ac%ec%97%85-%ea%b3%b5%ea%b8%89%ea%b8%b0%ec%97%85-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 8,
          img: require('@/assets/images/news/article01.jpg'),
          header:
            'KGI한국정책 역량개발원-테크나인㈜ <br/ > 컨소시엄-고령군 국비지원사업 선정 낭보',
          date: '2020.10.22',
          desc: '지자체 연구교육 전문기관 한국정책역량개발원(KGI, 원장 신판용)은 IoT솔루션 선도기업인 테크나인㈜과 컨소시엄을 구성하여 지난 16일 중소벤처기업부 주관 ‘고령군 국비지원실증사업’에 선정됐다고 22일 밝혔다. ',
          src: 'https://www.newsro.kr/kgi%ed%95%9c%ea%b5%ad%ec%a0%95%ec%b1%85%ec%97%ad%eb%9f%89%ea%b0%9c%eb%b0%9c%ec%9b%90-%e3%88%9c%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-%ec%bb%a8%ec%86%8c%ec%8b%9c%ec%97%84-%ea%b3%a0%eb%a0%b9%ea%b5%b0-%ea%b5%ad%eb%b9%84%ec%a7%80%ec%9b%90%ec%82%ac%ec%97%85-%ec%84%a0%ec%a0%95-%eb%82%ad%eb%b3%b4-%ea%b3%a0%eb%a0%b9%ea%b5%b0-%ec%a4%91%ec%86%8c%eb%b2%a4%ec%b2%98%ea%b8%b0%ec%97%85%eb%b6%80-%ec%88%98%ec%9a%94%ea%b8%b0%eb%b0%98%ea%b5%ac%eb%a7%a4%ec%a1%b0%ea%b1%b4%eb%b6%80-%ec%97%b0%ea%b5%ac%ea%b0%9c%eb%b0%9c%ec%a7%80%ec%9b%90%ec%82%ac%ec%97%85-%ec%84%a0%ec%a0%95/',
        },
        {
          index: 7,
          img: require('@/assets/images/news/article02.jpeg'),
          header:
            '한국정책역량개발원, IoT 솔루션 기업 <br/ > 테크나인(TECH9)과 업무협약(MOU) 체결!',
          date: '2020.10.20',
          desc: '지자체 공공부문 연구교육 전문기관 한국정책역량개발원(KGI, 원장 신판용)은 20일(화), IoT 솔루션 기업 테크나인(TECH9, 대표 이호동)과 업무협약(MOU)을 체결했다고 밝혔다.',
          src: 'https://www.newsro.kr/%ed%95%9c%ea%b5%ad%ec%a0%95%ec%b1%85%ec%97%ad%eb%9f%89%ea%b0%9c%eb%b0%9c%ec%9b%90-iot-%ec%86%94%eb%a3%a8%ec%85%98-%ea%b8%b0%ec%97%85-%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8tech9%ea%b3%bc-%ec%97%85%eb%ac%b4%ed%98%91%ec%95%bdmou-%ec%b2%b4%ea%b2%b0/',
        },
        {
          index: 6,
          img: require('@/assets/images/news/article03.jpg'),
          header:
            '테크나인, ‘2019 서울정원박람회’ 참가, <br/ >IOT 활용한 실외 환경 모니터링 시스템 선보여',
          date: '2019.10.07',
          desc: '남산 아래 언덕 위, 오래된 동네 ‘해방촌’ 일대가 발길 닿는 곳마다 꽃‧나무와 쉼터가 있는 정원마을이 된다.해방촌오거리 버스정류장, 텅 빈 빌라화단, 평소 폐지가 쌓여있던 동네 공터, 신흥시장 등 정원과는 거리가 멀 것 같은 해방촌 일대 곳곳에 총 32개 동네정원이 생긴다.  ',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2019-%ec%84%9c%ec%9a%b8%ec%a0%95%ec%9b%90%eb%b0%95%eb%9e%8c%ed%9a%8c-%ec%b0%b8%ea%b0%80-iot-%ed%99%9c%ec%9a%a9%ed%95%9c-%ec%8b%a4%ec%99%b8-%ed%99%98%ea%b2%bd-%eb%aa%a8%eb%8b%88%ed%84%b0%eb%a7%81-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ec%84%a0%eb%b3%b4%ec%97%ac/',
        },
        {
          index: 5,
          img: require('@/assets/images/news/article04.jpg'),
          header:
            '테크나인, 2019 아이어워즈에서 <br/ > ‘스마트환경 혁신대상’ 수상!',
          date: '2019.10.04',
          desc: '테크나인(대표 이호동)은 지난 2일, 서울 양재 엘타워에서 열린 2019 아이어워즈 대한민국 인터넷 혁신 대상(IoT 혁신 대상 부문) 평가에서 ‘스마트환경 혁신대상’을 수상했다고 4일(금) 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2019-%ec%95%84%ec%9d%b4%ec%96%b4%ec%9b%8c%ec%a6%88%ec%97%90%ec%84%9c-%ec%8a%a4%eb%a7%88%ed%8a%b8%ed%99%98%ea%b2%bd-%ed%98%81%ec%8b%a0%eb%8c%80%ec%83%81-%ec%88%98%ec%83%81/',
        },
        {
          index: 4,
          img: require('@/assets/images/news/article05.jpg'),
          header:
            '테크나인, 2019 월드 스마트시티 엑스포 참가 IoT활용 실외 안전모니터링 시스템 선보여',
          date: '2019.10.05',
          desc: '테크나인(대표 이호동)은 지난 2일, 서울 양재 엘타워에서 열린 2019 아이어워즈 대한민국 인터넷 혁신 대상(IoT 혁신 대상 부문) 평가에서 ‘스마트환경 혁신대상’을 수상했다고 4일(금) 밝혔다.',
          src: 'https://www.newsro.kr/%ed%85%8c%ed%81%ac%eb%82%98%ec%9d%b8-2019-%ec%95%84%ec%9d%b4%ec%96%b4%ec%9b%8c%ec%a6%88%ec%97%90%ec%84%9c-%ec%8a%a4%eb%a7%88%ed%8a%b8%ed%99%98%ea%b2%bd-%ed%98%81%ec%8b%a0%eb%8c%80%ec%83%81-%ec%88%98%ec%83%81/',
        },
        {
          index: 3,
          img: require('@/assets/images/news/article06.png'),
          header:
            '기술전문기업 R&D 사업 선정 <br/ > - 보급형 IoT 실외 대기질 측정 시스템',
          date: '2019.05.28',
          desc: '미세먼지 실시간 측정 및 예보를 위한 보급형 간이 측정기 및 공간정보 연개 대기질 정보 서비스 개발이 선정 되었습니다.',
          src: 'https://tech9cokr.wordpress.com/2019/06/10/2019%eb%85%84-%ea%b8%b0%ec%88%a0%ec%a0%84%eb%ac%b8%ea%b8%b0%ec%97%85-rd%ec%82%ac%ec%97%85-%ec%84%a0%ec%a0%95-%eb%b3%b4%ea%b8%89%ed%98%95-iot-%ec%8b%a4%ec%99%b8-%eb%8c%80%ea%b8%b0%ec%a7%88/',
        },
        {
          index: 2,
          img: require('@/assets/images/news/article07.jpg'),
          header: 'SK임업의 "모바일플랜터"에 smart forest <br> 제품 공급 계약',
          date: '2019.05.03',
          desc: 'IoT토양정보시스템으로 참가한 3개 전시회가 성황리에 종료되었습니다. 스마트팜 관련 많은 업체와 협력 상담이 진행되었습니다.',
          src: 'https://tech9cokr.wordpress.com/2019/06/10/sk%ec%9e%84%ec%97%85%ec%9d%98-%eb%aa%a8%eb%b0%94%ec%9d%bc%ed%94%8c%eb%9e%9c%ed%84%b0%ec%97%90-smart-forest-%ec%a0%9c%ed%92%88-%ea%b3%b5%ea%b8%89-%ea%b3%84%ec%95%bd/',
        },
        {
          index: 1,
          img: require('@/assets/images/news/article08.png'),
          header:
            'Tech 4orce 4차산업혁명과 지역발전(1)- <br/ >“4차산업혁명, 더 이상 미래의 선물이 아니라 도전”',
          date: '2019.04.30',
          desc: '공실활용 광고시스템에 대하여 SW 저작권 등록(20170828)을 하였으며, 특허 출원을 진행하여 기본적인 지재권을 확보하였습니다.',
          src: 'https://www.newsro.kr/%ec%b9%bc%eb%9f%bc-tech-4orce-4%ec%b0%a8%ec%82%b0%ec%97%85%ed%98%81%eb%aa%85%ea%b3%bc-%ec%a7%80%ec%97%ad%eb%b0%9c%ec%a0%841-4%ec%b0%a8%ec%82%b0%ec%97%85%ed%98%81%eb%aa%85-%eb%8d%94-%ec%9d%b4%ec%83%81-%eb%af%b8%eb%9e%98%ec%9d%98-%ec%84%a0%eb%ac%bc%ec%9d%b4-%ec%95%84%eb%8b%88%eb%9d%bc-%eb%8f%84%ec%a0%84%ec%9d%b4%eb%8b%a4/',
        },
      ],
    },
  },
  actions,
  mutations,
});
